import React from 'react'
import TabContent from '@fdn/tab_content'
import ArticleCardHome from 'Components/article_card_home'
import ButtonMore from '@fdn/button_more'
import { handleUrlArticle } from 'Helpers/utils'
import parsingUrl from 'Helpers/parsingUrl'

export default function TabArticleMobile({ tabData, link_cta, is_mobile }) {
  return (
    <div className="tab-article-wrapper">
      <TabContent is_mobile={true} title="BEAUTY">
        {Array.isArray(tabData) ? (
          tabData.length ? (
            <>
              {tabData.map((tab, id) => (
                <div
                  key={id}
                  id_tab_button={`id_home_${tab.slug}`}
                  label={tab.slug.replace(/[-]/g, ' ').toUpperCase()}
                  className="tab-article-inner-wrapper"
                >
                  {tab.posts.slice(0, 3).map((item, idx) => (
                    <ArticleCardHome
                      key={idx}
                      is_mobile={is_mobile}
                      is_sponsored={false}
                      font_title={`Josefin Sans, sans-serif`}
                      article={Object.assign({
                        category: { name: tab.slug.replace(/[-]/g, ' ') },
                        date: item.created_at,
                        title: item.title,
                        text: item.excerpt,
                        image: parsingUrl(item.image) || "",
                        url: handleUrlArticle(item.url),
                        author: { fullname: item.author.name },
                      })}
                      url_category={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/category/${tab.slug}`}
                      url_author={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/author/${item.author.username}`}
                      type={'type-2'}
                    />
                  ))}
                  <div className="btn-more-wrapper">
                    <ButtonMore
                      text={
                        'SEE ARTICLES IN ' +
                        tab.slug.replace(/[-]/g, ' ').toUpperCase()
                      }
                      font_family={`Josefin Sans, sans-serif`}
                      font_size={12}
                      font_weight={400}
                      color={'#000000'}
                      link={link_cta + '/' + tab.slug}
                      element_id={`id_see_more_article_${tab.slug}`}
                    />
                  </div>
                </div>
              ))}
              <div is_always_show={'true'} className="col-ads p-0">
                {/* <img
                  src="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/web-assets/ads/ads+editorial.png"
                  alt="ads-detik"
                /> */}
              </div>
            </>
          ) : null
        ) : null}
      </TabContent>
      <style>
        {`
          .tab-article-wrapper .fdn-article-card-mobile-type-2 {
            margin-bottom: 16px;
          }

          .tab-article-wrapper .btn-more-wrapper {
            margin-top: 30px;
            text-align: center;
          }

          .tab-article-wrapper .btn-more-wrapper .btn-see-more {
            margin: 0 auto;
          }

          .tab-article-wrapper .col-ads {
            margin-top: 34.5px;
          }

          .fdn-article-card-mobile-type-2 div.article-info a.article-title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; 
            -webkit-box-orient: vertical;
          }
        `}
      </style>
    </div>
  )
}
