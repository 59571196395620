import React from 'react'
import Desktop from './tab_article_desktop'
import Mobile from './tab_article_mobile'

export default function TabArticle({
  is_mobile,
  dataAdsBeauty,
  article,
  link_cta,
  tabData,
}) {
  const props = {
    is_mobile: is_mobile || false,
    dataAdsBeauty: dataAdsBeauty || false,
    article: article || [],
    link_cta:
      link_cta ||
      process.env.NEXT_PUBLIC_ROOT_DOMAIN,
    tabData: Array.isArray(tabData) ? tabData : [],
  }

  if (!is_mobile) {
    return <Desktop {...props} />
  } else {
    return <Mobile {...props} />
  }
}
