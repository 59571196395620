import React from 'react'
import TabContent from '@fdn/tab_content'
import Banner from 'Components/banner/banner'
import ArticleCardHome from 'Components/article_card_home'
import ButtonMore from '@fdn/button_more'
import { handleUrlArticle } from 'Helpers/utils'
import parsingUrl from 'Helpers/parsingUrl'

export default function TabArticleDesktop({
  tabData,
  link_cta,
  is_mobile,
  dataAdsBeauty,
}) {
  return (
    <div className="tab-article-wrapper">
      <TabContent is_mobile={is_mobile} title="BEAUTY">
        {Array.isArray(tabData)
          ? tabData.length
            ? tabData.map((tab, id) => (
              <div
                key={id}
                id_tab_button={`id_home_${tab.slug}`}
                label={tab.slug.replace(/[-]/g, ' ').toUpperCase()}
                className="tab-article-inner-wrapper"
              >
                {tab.posts.slice(0, 3).map((item, idx) => (
                  <ArticleCardHome
                    key={idx}
                    is_mobile={is_mobile}
                    is_sponsored={false}
                    font_title={`Josefin Sans, sans-serif`}
                    article={Object.assign({
                      category: {
                        name: tab.slug.replace(/[-]/g, ' '),
                      },
                      date: item.created_at,
                      title: item.title,
                      text: item.excerpt,
                      image: parsingUrl(item.image) || "",
                      url: handleUrlArticle(item.url),
                      author: { fullname: item.author.name },
                    })}
                    url_category={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/category/${tab.slug}`}
                    url_author={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/author/${item.author.username}`}
                    type={'type-2'}
                  />
                ))}
                <div className="btn-more-wrapper">
                  <ButtonMore
                    text={
                      'SEE ARTICLES IN ' +
                      tab.slug.replace(/[-]/g, ' ').toUpperCase()
                    }
                    font_family={`Josefin Sans, sans-serif`}
                    font_size={12}
                    font_weight={400}
                    color={'#000000'}
                    link={link_cta + '/' + tab.slug}
                    element_id={`id_see_more_article_${tab.slug}`}
                  />
                </div>
              </div>
            ))
            : null
          : null}
        <div is_always_show={'true'} className="side-ads-wrapper">
          <Banner
            name="mediumrectangle2"
            placeholder="mediumrectangle2"
            is_mobile={is_mobile}
            class_name="ads-medium-rectangle"
          />
        </div>
      </TabContent>
      <style jsx>
        {`
          .side-ads-wrapper {
            width: ${dataAdsBeauty ? '300px !important' : '0px !important'};
            min-width: ${dataAdsBeauty ? '300px !important' : '0px !important'};
            height: 250px;
            overflow: hidden;
          }

          .side-ads-wrapper img {
            width: 300px !important;
            height: 250px;
            max-width: 100%;
          }

          .side-ads-wrapper:empty {
            display: none !important;
          }
        `}
      </style>
      <style>
        {`
          .tab-article-inner-wrapper {
            display: flex;
            flex-wrap: wrap;
            max-width: 1200px;
          }

          .tab-article-inner-wrapper .fdn-article-card-desktop-type-2 {
            width: calc(33.333334% - 24px);
            padding-right: 24px;
          }

          .tab-article-inner-wrapper .fdn-article-card-desktop-type-2:nth-last-child(2) {
            padding-right: 0;
          }

          .tab-article-inner-wrapper .fdn-article-card-desktop-type-2 .article-title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }

          .tab-article-inner-wrapper
            .article-card-desktop-type-2
            .article-image {
            height: 174px;
          }

          .tab-article-wrapper .ads-wrapper {
            width: 300px;
          }

          .tab-article-wrapper .btn-more-wrapper {
            margin-top: 35px;
          }

          .ads-medium-rectangle:empty {
            display: none;
          }
        `}
      </style>
    </div>
  )
}
